export const REWARDS_GROUP_STATUS_ACTIVE = {
  AVAILABLE: 'available',
};

export const REWARDS_GROUP_STATUS_INACTIVE = {
  EARNED: 'earned',
  REDEEMED: 'redeemed',
  EXPIRED: 'expired',
  LOCKED: 'locked',
  CLIPPED: 'clipped',
};
export const REWARDS_LENGTH_1 = 1;

export const SIZE_ONE = 1;

export const redemptionChannelsAvailable = ['discount', 'cashback'];

export const clubLocations = ['clubOfferActive', 'allClub', 'activeclub'];

export const PERSISTENT_ENVS = ['PROD', 'INTEGRATION', 'QA'];

export const MonthToDaysMap = {
  1: '31',
  2: '29',
  3: '31',
  4: '30',
  5: '31',
  6: '30',
  7: '31',
  8: '31',
  9: '30',
  10: '31',
  11: '30',
  12: '31',
};

export const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getRewardTypes = (subColor, mainColorRGB) => ({
  newRewardActive: {
    bgColor: subColor,
    letLabel: 'earnedrewardLabel',
    rightLabel: 'New',
    BtnText: 'Claim Reward',
  },
  newRewardDisable: {
    bgColor: '#CCD1D7',
    letLabel: 'earnedrewardLabel',
    rightLabel: 'New',
    BtnText: 'Next Reward',
  },
  newRewardClipped: {
    bgColor: subColor,
    letLabel: 'earnedrewardLabel',
    rightLabel: 'Redemption Required',
    BtnText: 'Claimed',
  },
  newRewardRedeemed: {
    bgColor: `rgba(${mainColorRGB},.4)`,
    letLabel: 'earnedrewardLabel',
    rightLabel: 'Redeemed',
    BtnText: 'Claimed',
  },
});

export const getLoyaltyReward = (item) => {
  const { rewardGroups } = item;
  const obj = {};
  rewardGroups.map((reward, index) => {
    if (obj[item.id] === undefined) {
      obj[item.id] = {};
    }
    if (obj[item.id].total === undefined) {
      obj[item.id].total = 0;
    }
    if (obj[item.id].claimed === undefined) {
      obj[item.id].claimed = 0;
    }
    if (obj[item.id].available === undefined) {
      obj[item.id].available = 0;
    }
    if (obj[item.id].locked === undefined) {
      obj[item.id].locked = 0;
    }
    if (obj[item.id].targetAchieved === undefined) {
      obj[item.id].targetAchieved = 0;
    }
    reward.rewards.map((offer) => {
      if (offer.offer.status === 'redeemed') {
        obj[item.id].claimed++;
      }
      if (offer.offer.status === 'available') {
        obj[item.id].available++;
      }
      if (offer.offer.status === 'locked') {
        obj[item.id].locked++;
      }
      if (offer.targetAchieved) {
        obj[item.id].targetAchieved += offer.targetAchieved;
      }
      obj[item.id].total++;
    });
  });
  return obj;
};
export const getLoyaltyPoints = (rewards) => {
  const obj = {};
  if (rewards && rewards.length > 0) {
    rewards.map((item) => {
      obj[item.id] = getLoyaltyReward(item);
    });
  }
  return obj;
};

export function convertCentsToDollars(cents) {
  return cents / 100;
}
