const messages = {
  fr: {
    'Month.January': 'janvier',
    'Month.February': 'février',
    'Month.March': 'mars',
    'Month.April': 'avril',
    'Month.May': 'mai',
    'Month.June': 'juuin',
    'Month.July': 'juillet',
    'Month.August': 'août',
    'Month.September': 'septembre',
    'Month.October': 'octobre',
    'Month.November': 'novembre',
    'Month.December': 'décembre',

    'Currency.name': 'Euro',
    'Currency.symbol': '€',
    'Currency.items': 'items',
    'Currency.pointsName': 'points',

    'Footer.PrivacyPolicyLocale': 'Politique de confidentialité',
    'Footer.TermsAndConditionsLocale': 'Termes et conditions',
    'Footer.Release': 'Libérer',
    'Footer.CookieNotice': 'Vos choix en matière de confidentialité',

    'Error.userEmailCantSend': 'Le courriel ne peut être envoyé',
    'Error.userRegisterError':
      "Votre demande ne peut pas être complétée. Veuillez réessayer. Si cela continue à se produire, veuillez contacter l'équipe d'assistance.",
    'Error.userRegisterConflict':
      'Votre inscription ne peut être traitée, un conflit existe concernant les données.',
    'Error.userProfileError':
      'Votre profil ne peut être mis à jour, vérifiez s’il n’y a pas d’erreur et réessayez.',
    'Error.userUpdatePasswordError':
      'Une erreur s’est produite dans le changement de votre mot de passe. Revérifiez votre mot de passe actuel.',
    'Error.userLoginError':
      'Votre courriel ou votre mot de passe n’a pas pu être validé, réessayez.',
    'Error.userForgotPassword':
      'Si vous avez compte chez nous, un courriel a été envoyé à l’adresse fournie. Vérifiez votre messagerie pour d’autres instructions.',
    'Error.userResetPassword': 'Le courriel de réinitialisation a échoué.',
    'Error.userUserData': 'Erreur dans l’obtention des données de l’utilisateur',
    'Error.userConflict': 'Conflit',
    'Error.userGeneral': 'Erreur',
    'Error.IncorrectEmail': 'identifiant ou mot de passe incorrect',

    'Error.fieldValidationPasswordDoNotMatch': 'Les mots de passe ne correspondent pas',
    'Error.fieldValidationCheckTheForm': 'Veuillez vérifier les valeurs du formulaire',
    'Error.fieldValidationEmptyField': 'Le champ ne doit pas être vide',
    'Error.fieldValidationFirstNameCharLimit': 'Le prénom doit compter moins de 255 caractères',
    'Error.fieldValidationLastNameCharLimit': 'Le prénom doit compter moins de 255 caractères',
    'Error.fieldValidationBirthday':
      'La date de naissance ne peut être aujourd’hui, indiquez la bonne date',
    'Error.fieldValidationBadEmailFormat': 'Format de courriel incorrect',
    'Error.fieldValidationPasswordLength': 'Le mot de passe doit compter au moins 8 caractères.',
    'Error.fieldValidationPhoneDigits': 'Le téléphone doit compter 10 chiffres.',
    'Error.fieldValidationPhoneOnlyDigits': 'Le champ ne peut contenir que des numéros',
    'Error.fieldValidationBadPhoneformat': 'Format de téléphone incorrect',
    'Error.fieldValidationAddressLength': "L'adresse doit contenir moins de 255 caractères",
    'Error.fieldValidationCityLength': 'La ville doit compter moins de 255 caractères.',
    'Error.fieldValidationZipLength': 'Le code postal ne correspond pas au numéro requis.',
    'Error.invalidDate': 'Date invalide',

    'Error.passwordChangingEmail':
      'Une erreur s’est produite lors du changement de votre mot de passe. Cliquez à nouveau sur le lien dans le courriel.',
    'Error.passwordChanging': 'Vérifiez les erreurs dans les champs du mot de passe.',

    'Error.cashBackPostingPayment':
      "Le transfert a échoué. Vous ne pouvez transférer de l'argent qu'une seule fois en 24 heures.",
    'Error.offerDetailsNotFound': 'Ce coupon est actuellement indisponible',
    'Error.offerExpired': "L'offre demandée n'est pas disponible",

    'Success.cashBack': 'Succès! Votre argent a été transféré.',
    'Success.passwordUpdate': 'Votre mot de passe a été mis à jour avec succès!',
    'Success.passwordReset': 'Votre mot de passe a été réinitialisé. Connectez-vous maintenant.',
    'Success.passwordEmailResetSent':
      'Si vous avez compte chez nous, un courriel a été envoyé à l’adresse fournie. Vérifiez votre messagerie pour d’autres instructions.',

    'Button.signIn': 'IDENTIFICATION',
    'Button.login': 'CONNEXION',
    'Button.reset': 'RÉINITIALISER',
    'Button.update': 'MISE À JOUR',
    'Button.logout': 'DÉCONNEXION',
    'Button.cashBack': 'get cash back',
    'Button.save': 'save',
    'Button.cancel': 'ANNULER',
    'Button.confirm': 'CONFIRMER',
    'Button.register': "S'INSCRIRE",
    'Button.transfer': 'TRANSFERT',
    'Button.createWallet': 'CRÉER UN PORTEFEUILLE ÉLECTRONIQUE',
    'Button.send': 'ENVOYER',
    'Button.forgotPassword': 'MOT DE PASSE OUBLIÉ',
    'Button.toTop': 'HAUT DE PAGE',
    'Button.clipReward': 'récompense de clip',
    'Button.clipCoupon': 'Coupon de clip',
    'Button.clippedCoupon': 'coupée',
    'Button.clippedWallet': 'coupon coupée',
    'Button.clippedWalletCashBack': 'remise en argent coupée',
    'Button.enroll': 'inscrire',
    'Button.loading': 'loading...',
    'Button.enrolled': 'inscrite',
    'Button.updatePassword': 'Mettre à jour le mot de passe',
    'Button.UpdatePreferStore': 'Mettre à jour ma boutique préférée',
    'ButtonCash.cancel': 'Annuler',
    'ButtonCash.confirm': 'Confirmer',
    'Button.done': 'Fait',
    'Button.Coupon': 'Coupon',
    'Button.cashback': 'Remise en argent',
    'Button.showChangeStoreOption': 'Changer de magasin',
    'Button.channgePreferStoreLabel': 'Trouver un magasin à proximité',
    'Modal.finePrint': 'PETITS CARACTÈRES',
    'InputField.password': 'Mot de passe',

    'Splash.saveUpTo': "ÉCONOMISEZ JUSQU'À ",
    'Splash.today': "AUJOURD'HUI",
    'Splash.with': 'AVEC',
    'Splash.digitalCoupons': 'LES BONS NUMÉRIQUES!',
    'Splash.alreadyHaveAnAccount': 'Vous avez déjà un compte?',
    'Splash.emptyText': 'Il n’y pas d’offres disponibles pour le moment.',

    'Login.login': 'Connexion',
    'Login.enterInfo':
      'Indiquez vos renseignements ci-dessous pour voir toutes vos formidables offres personnalisées.',
    'Login.anyField': 'Tout champ avec un astérisque (*) est considéré comme obligatoire.',
    'Button.signUp': "S'INSCRIRE",
    'login.signIn': "S'identifier",
    'login.dontAccount': "Vous n'avez pas de compte ?",
    'login.createAccount': 'Créer un compte',

    'Account.yourAccount': 'Votre compte',
    'Account.profile': 'PROFIL',
    'Account.editAccountInformation': 'MODIFIER L’INFO DU COMPTE',
    'Account.accountPassword': 'MOT DE PASSE DU COMPTE',
    'Account.updatePassword': 'METTRE À JOUR LE MOT DE PASSE',
    'Account.transactionHistory': 'HISTORIQUE',
    'Account.viewYourTransactionHistory': 'VOIR L’HISTORIQUE',
    'Account.version': 'Version',

    'Account.CurrentPassword': 'Mot de passe actuel',
    'Account.NewPassword': 'Nouveau mot de passe',
    'Account.ConfirmNewPassword': 'Confirmer le nouveau mot de passe',
    'Account.ChangePwd': 'Mot de passe',
    'Account.PreferredStore': 'Magasin préféré',

    'BurgerMenu.myClippedOffers': 'Mes offres coupées',
    'BurgerMenu.totalAvailableSavings': 'Économies disponibles',
    'BurgerMenu.totalClippedSavings': 'Total des économies clipsées',
    'BurgerMenu.yearToDateSavings': 'Économies réalisées à ce jour',
    'BurgerMenu.myDigitalCoupons': 'Mes coupons numériques',
    'BurgerMenu.coupons': 'Bons',
    'BurgerMenu.club': 'Club',
    'BurgerMenu.allClubOffers': 'Toutes les offres du club',
    'BurgerMenu.activeClubOffers': 'Offres du Club coupée',
    'BurgerMenu.myClubOffers': 'Mes récompenses du Club',
    'BurgerMenu.rewards': 'Récompenses',
    'BurgerMenu.myEwallet': 'Mon portefeuille électronique',
    'BurgerMenu.helpfulLinks': 'Liens utiles',
    'BurgerMenu.profile': 'Profil',
    'BurgerMenu.personalInformation': 'Renseignements personnels',
    'BurgerMenu.accountPassword': 'Mot de passe du compte',
    'BurgerMenu.transactionHistory': 'Historique',
    'BurgerMenu.logout': 'Déconnexion',
    'BurgerMenu.logoutModalAreYouSure': 'Voulez-vous vraiment vous déconnecter de votre compte',
    'BurgerMenu.cashBack': 'Remise en argent',
    'BurgerMenu.cashBackActive': 'Remise en argent active',
    'BurgerMenu.cashBackCashOut': 'Encaisser',
    'BurgerMenu.cashBackAccount': 'Compte de retrait',

    'ChangePassword.header': 'Mot de passe du compte',
    'ChangePassword.subHeader':
      'Notez que l’information liée au mot de passe ci-dessous se rapporte seulement à celle utilisée dans le cadre de notre programme de récompense client.',
    'ChangePassword.fineText':
      'Tous les mots de passe doivent compter au moins 8 caractères. Tout champ avec un astérisque (*) est considéré comme obligatoire.',
    'ChangePassword.currentPassword': 'Mot de passe actuel',
    'ChangePassword.newPassword': 'Nouveau mot de passe',
    'ChangePassword.confirmPassword': 'Confirmez le mot de passe',

    'Header.login': 'Connexion',
    'Header.hello': 'Bonjour',
    'Header.shopper': 'Acheteur',
    'Header.your': 'Votre compte',
    'Header.account': ' ',

    'SearchBar.myAccount': 'Mon compte',
    'SearchBar.profile': 'Profil',
    'SearchBar.myEwallet': 'Mon portefeuille électronique',
    'SearchBar.coupons': 'Bons',
    'SearchBar.clubOffers': 'Offres du Club',
    'SearchBar.rewards': 'Récompenses',
    'SearchBar.cashBack': 'Remise en argent',
    'SearchBar.eWallet': 'Mon portefeuille électronique',
    'SearchBar.searchForTheOffers': 'Rechercher des offres',

    'SearchBar.emailSending': 'Veuillez patienter pendant que nous envoyons votre e-mail',
    'SearchBar.emailSent': 'Un e-mail a été envoyé, veuillez vérifier votre boîte de réception',

    'SubNavBar.selectOfferPool': "Groupe d'offres",
    'SubNavBar.selectViewBy': 'Voir par',
    'SubNavBar.selectEmptyText': 'Sélectionner..',
    'SubNavBar.selectOptionHighestValue': 'Valeur la plus élevée',
    'SubNavBar.selectOptionNewest': 'Nouveautés',
    'SubNavBar.selectOptionLowestValue': 'Valeur la moins élevée',
    'SubNavBar.selectOptionExpiring': 'Expirant',
    'SubNavBar.selectOptionCategory': 'Date d’expiration',

    'SubNavBar.navigationGallery': 'Galerie',
    'SubNavBar.navigationEwallet': 'Portefeuille électronique',
    'SubNavBar.navigationAllCubOffers': 'Toutes les offres du club',
    'SubNavBar.navigationActiveClubOffers': 'Offres du Club coupée',
    'SubNavBar.navigationMyClubOffers': 'Mes récompenses de club',
    'SubNavBar.navigationPersonalInformation': 'Renseignements personnels',
    'SubNavBar.navigationAccountPassword': 'Mot de passe du compte',
    'SubNavBar.navigationTransactionHistory': 'Historique',
    'SubNavBar.navigationClippedOffers': 'offres coupées',
    'SubNavBar.navigationCashBack': 'Remise en argent',
    'SubNavBar.navigationCashOut': 'Encaisser',
    'SubNavBar.navigationCashOutAccount': 'Compte de retrait',

    'ForgotPassword.header': 'Mot de passe oublié',
    'ForgotPassword.subHeader':
      'Indiquez votre adresse courriel ci-dessous et nous vous enverrons un courriel avec les étapes à suivre pour réinitialiser votre mot de passe.',
    'ForgotPassword.fineText': 'Tout champ avec un astérisque (*) est considéré comme obligatoire.',
    'ForgotPassword.email': 'Adresse électronique',
    'ForgotPassword.forgot': 'Mot de passe oublié?',
    'Forgot.send': 'Envoyer',

    'Profile.header': 'Renseignements personnels',
    'Profile.subHeaderStart': 'Ces renseignements sont seulement utilisés par',
    'Profile.subheaderEnd': 'et ne seront distribués à aucune tierce partie.',
    'Profile.fineText': 'Tout champ avec un astérisque (*) est considéré comme obligatoire.',
    'Profile.updateSuccess': 'Votre profil a été mis à jour avec succés!',
    'Profile.preferredStoreDesc':
      'Les offres et les e-mails refléteront les économies et promotions disponibles dans le magasin sélectionné.',
    'Profile.channgePreferStoreLabel': 'Trouver un magasin à proximité',
    'Profile.preferStoresSearchEmptyMsg':
      'Aucun magasin trouvé avec cette adresse, veuillez réessayer avec un code postal différent',
    'Profile.channgePreferStorePlaceholder': 'Recherche par ville ou code postal',
    'Profile.channgePreferStoreNoLocFoundMsg':
      'Aucun emplacement trouvé à proximité, affichant le plus proche',

    'Recommended.justClipped': 'Simplement clipsé',
    'Recommended.youMightAlsoLike': 'Vous aimerez peut-être aussi',
    'Recommended.clip': 'Clipsez',
    'Recommended.clipped': 'Clipsé',

    'UserHeader.totalAvailableSavings': 'Économies disponibles',
    'UserHeader.totalClippedSavings': 'Total des économies clipsées',
    'UserHeader.YTDSavings': 'Économies réalisées à ce jour ',
    'UserHeader.AllClubSavings': 'Toutes les offres du Club disponibles',

    'Savings.AvailableSavings': 'Économies disponibles',
    'Savings.ClippedSavings': 'Économies clipsées',
    'Savings.CashOutAvailable': 'Encaissement disponible',
    'Savings.CashOutEarned': 'Vous avez gagné',
    'Savings.YeartoDateSavings': "Économies cumulées depuis le début de l'année",
    'Savings.CashOut': 'Encaissement',

    'Register.howToClick': 'Cliquez',
    'Register.howToClickText':
      'Une fois inscrit, vous recevrez vos bons électroniques par courriel chaque semaine. CLIPSEZ numériquement les offres désirées pour les charger dans votre portefeuille électronique personnel.',
    'Register.howToShop': 'Magasinez',
    'Register.howToShopText':
      'Sélectionnez vos produits et présentez votre identifiant d’acheteur (numéro de téléphone) à la caisse chaque fois que vous magasinez.',
    'Register.howToShopAccent': 'Clipsez pendant que vous magasinez!',
    'Register.howToSave': 'Économisez',
    'Register.howToSaveText':
      'Les économies de vos bons électroniques seront automatiquement déduites du sous-total de vos achats.',
    'Register.howToSaveAccent': "C'est si facile!",
    'Register.header': 'Créez un compte',
    'Register.subHeader':
      'Merci de votre inscription! Veuillez entrer vos renseignements ci-dessous pour recevoir vos bons numériques personnalisés. Vous devez avoir un courriel valide pour vous inscrire. Gardez un œil sur les aubaines qui s’offriront à vous.',
    'Register.fineText': 'Tout champ avec un astérisque (*) est considéré comme obligatoire.',
    'Register.privacyPolicyBodyText':
      "En créant un compte, vous acceptez les conditions d'utilisation décrites", // TODO: Have a french speaker translate this set of strings.
    'Register.privacyPolicyLinkText': 'ici', // They are currently just run through Google Translate
    'Register.required': 'est requis',

    'ResetPassword.header': 'Réinitialisez votre mot de passe',
    'ResetPassword.subHeader': 'Indiquez votre nouveau mot de passe ci-dessous.',
    'ResetPassword.fineText': 'Tout champ avec un astérisque (*) est considéré comme obligatoire.',
    'ResetPassword.password': 'Mot de passe',
    'ResetPassword.confirmPassword': 'Confirmez le mot de passe',
    'ResetPassword.errorPasswordLengthGreaterThan8': 'Password must be at least 8 characters',
    'ResetPassword.errorPasswordNotMatch': 'Password did not match',
    'ResetPassword.subText': 'Enter a new password below to change your password.',
    'ResetPassword.reset': 'Reset Password',
    'ResetPassword.backtoLogin': 'Back To Login',
    'ResetPassword.loginSuccess': 'You’re password has been changed successfully. ',
    'ResetPassword.headerLoginSuccess': 'Password Changed!',
    'ResetPassword.newPassword': 'New Password',

    'Rewards.totalRewardPoints': 'Nombre total de points de récompense',
    'Rewards.emptyText':
      'Vos avez clipsé toutes les offres de récompense! Revenez plus tard pour de nouvelles offres du Club.',
    'Rewards.bannerText': 'Récompense de points',
    'Rewards.rewardBalance': 'Solde de récompense',
    'Rewards.pointsReward': 'Récompense de points',

    'ClubOffers.pageTitle': 'Club Offers',
    'AllClub.emptyText':
      'Vous avez découpé toutes les offres du club! Afficher vos offres de club écrêtées...',
    'ActiveClub.emptyText':
      'Vous n’avez clipsé aucune offre du Club! Vérifiez la section Club pour connaître les options disponibles.',

    'ActiveClub.usesAvailable': 'utilisations disponibles',
    'ActiveClub.use': 'les usages',
    'ActiveClub.timesPerTransaction': 'fois par transation',
    'ActiveClub.pointsName': 'POINTS',
    'ActiveClub.spendName': 'DÉPENSÉ',
    'ActiveClub.itemsName': 'ACHETÉE',

    'ClubOffers.reward': 'Récompense',
    'ClubOffers.pointsName': 'Points',
    'ClubOffers.completed': 'Complété',
    'ClubOffers.points': 'points',
    'ClubOffers.spendName': 'Dépenser',
    'ClubOffers.earn': 'Gagner',
    'ClubOffers.itemsName': 'Achat',
    'ClubOffers.locked': 'Fermé à clé',
    'ClubOffers.clipped': 'Revendiqué',
    'ClubOffers.available': 'Réclamation récompense',
    'ClubOffers.items': 'Articles',
    'ClubOffers.clubProgressTitle': 'Progrès du club',
    'ClubOffers.validDateLabel': 'Valable jusque',
    'ClubOffers.redeemDateLabel': 'Échanger par',
    'ClubOffers.additionalDetailsTitle': 'Détails supplémentaires',
    'ClubOffers.unlimitedUse': 'Utilisation illimitée',
    'ClubOffers.rewardsEarnedHash': '# of rewards earned',
    'ClubOffers.enroll': 'Inscrire',
    'ClubOffers.enrolled': 'Inscrit',
    'ClubOffers.rewardsTitle': 'Récompenses',
    'ClubOffers.earnedRewardsTitle': 'Récompenses gagnées',
    'ClubOffers.NextReward': 'Récompense suivante',
    'ClubOffers.RewardAvailable': 'Disponible',
    'ClubOffers.noRewardEmptyMsg': "Vous n'avez pas encore de récompenses",
    'Cluboffers.earnedrewardLabel': 'Récompense gagnée',
    'ClubOffers.EarnedRewardDesc':
      "Réclamez vos récompenses disponibles ! Pour rendre la prochaine récompense disponible, il vous suffit d'utiliser la récompense que vous avez déjà réclamée. C'est si simple!",
    'ClubOffers.date': 'Date',
    'ClubOffers.clubOffersEmptyMsgtext':
      'Commencez dès maintenant à gagner des récompenses en participant à un club sur la gauche. Sélectionnez, magasinez et suivez vos progrès vers des avantages passionnants !',
    'ClubOffers.earned': 'Gagné',
    'Cluboffers.redeemed': 'Rachetée ',
    'ClubOffers.clubOffersEmptyMsg': 'Façons de gagner',
    'ClubOffer.expiring': 'EXPIRANT BIENTÔT',
    'ClubOffers.claim': 'Réclamation récompense',
    'ClubOffers.clipRewardErrorMsg':
      "Impossible de découper la récompense, veuillez contacter l'équipe d'assistance.",
    'ClubOffer.additionalDetailsTitle': 'Détails supplémentaires',

    'MyClub.emptyText':
      'Vous n’avez clipsé aucune récompense du Club! Vérifiez la section Club pour connaître toutes les options disponibles en matière de récompense.',

    'Transactions.Header': 'Historique',
    'Transactions.subHeader':
      'Les transactions peuvent prendre jusqu’à 48 heures avant de s’afficher sur votre compte.',
    'Transactions.emptyText':
      'Vous n’avez aucune transaction disponible. Échangez quelques offres et réessayez.',
    'Transactions.columnOffer': 'Offre',
    'Transactions.columnValue': 'Valeur',
    'Transactions.columnStatus': 'Statut',
    'Transactions.columnDate': 'Date',

    'Wallet.tapText':
      'Ne ratez pas une occasion d’économiser! Effleurez les articles pour les cocher sur votre liste de magasinage.',
    'Wallet.emptyText':
      'Ne ratez pas une occasion d’économiser! Effleurez les articles pour les cocher sur votre liste de magasinage.',
    'Wallet.viewTransactionsHistory': "VOIR L'HISTOIRE",
    'Wallet.cashback': 'Clipped: Cash back',
    'Wallet.discount': 'Clipped: Coupon',

    'Offers.whenYouBuy': 'Quand acheter',
    'Offers.expires': "L'offre prend fin le",
    'Offers.clipped': 'Clipsé',
    'Offers.clippedUpper': 'CLIPSÉ',
    'Offers.couponDetails': 'détails des bon',
    'Offers.clipIt': 'CLIPSEZ-LE',
    'Offers.canBeUsed': 'Peut être utilisé jusqu’au',
    'Offers.times': 'fois',
    'Offers.newDeal': 'NOUVELLE AUBAINE',
    'Offers.endingSoon': 'PREND BIENTÔT FIN',
    'Offers.endDate': 'Earn through: ',
    'Offers.expirationDate': 'Redeem through: ',

    'Offers.congratulations': 'Toutes nos félicitations',
    'Offers.rewardAvailable': "L'offre de récompense est disponible pour la coupure",
    'Offers.unlimitedUse': 'UTILISATION ILLIMITÉE',
    'Offers.useUpTo': 'UTILISATION',
    'Offers.used': 'utilisé',
    'Offers.of': 'sur',
    'Offers.purchased': 'ACHETÉE',
    'Offers.earned': 'Gagné',
    'Offers.offerDetails': "Détails de l'offre",
    'Offers.statusAvailable': 'DISPONIBLE',
    'Offers.statusClipped': 'COUPÉE',
    'Offers.statusRedeemed': 'RACHETÉE',
    'Offers.statusExpired': 'EXPIRÉ',
    'Offers.points': 'Points',
    'Offers.featuredOffersUnavailable': 'Vous avez coupé toutes les offres de cette campagne!',
    'Offers.additionalDetails': 'Additional Details',
    'Offers.hoverText': 'Flex offer',
    'Offers.clipBefore': 'Clip avant',
    'Offers.redeemFrom': 'Échanger depuis',
    'Offers.sortBy': 'Trier par',

    'OffersGallery.weeklySalesLink': 'Cliquez ici pour vos offres hebdomadaires!',
    'OffersGallery.badSearch':
      'Nous n’avons pas pu trouver ce que vous recherchez. Revenez plus tard.',
    'OffersGallery.allClipped':
      'Vous avez clipsé toutes les offres! Revenez plus tard pour de nouvelles offres.',
    'OffersGallery.categoryAllClipped':
      'Vous avez clipsé toutes les offres dans cette catégorie! Revenez plus tard pour de nouvelles offres.',
    'OffersGallery.categoryBadSearchStart': 'Nous n’avons rien trouvé avec le mot-clé',
    'OffersGallery.categoryBadSearchEnd': 'dans cette catégorie.',

    'Category.produce': 'Produire',
    'Category.pet': 'Fournitures pour animaux',
    'Category.beverage': 'Boisson',
    'Category.dairy': 'Laitière',
    'Category.deli': 'Épicerie',
    'Category.frozen': 'Congelée',
    'Category.general': 'Générale',
    'Category.grocery': 'Épicerie',
    'Category.meat': 'Viande et fruits de mer',
    'Category.packaged_meat': 'Viande emballée',
    'Category.snacks': 'Collation & Bonbons',
    'Category.beauty': 'Santé & Beauté',
    'Category.entertainment': 'Divertissement',
    'Category.books': 'Livres & Les magazines',
    'Category.gifts': 'Fleurs & Cadeaux',
    'Category.auto': 'Automobile',
    'Category.office': 'Fournitures de bureau',
    'Category.toys': 'Jouets & Jeux',
    'Category.baby': 'Bébé',
    'Category.bakery': 'Boulangerie',
    'Category.cereal': 'Petit déjeuner & Céréale',
    'Category.soups': 'Produits en conserve & Les soupes',
    'Category.international': 'Internationale',
    'Category.home': 'Accueil',
    'Category.pharmacy': 'Pharmacie',
    'Category.alcohol': 'Du vin, Bière & Spiritueux',
    'Category.tobacco': 'Le tabac',
    'Category.organic': 'Biologique',
    'Category.aarp': 'AARP',
    'Category.special': 'Spéciale',
    'Category.baking': 'Cuisson',
    'Category.kosher': 'Kascher',
    'Category.featured': 'Offre en vedette',
    'Category.apparel': 'Vêtements',
    'Category.electronics': 'Électronique',
    'Category.restaurant': 'Restaurant',
    'Category.travel': 'Voyage',
    'Category.gift_card': 'Carte cadeau',

    'CashOutAccount.header': 'Ajouter des comptes',
    'CashOutAccount.subText':
      'Ajoutez un autre compte à votre profil en entrant les informations ci-dessous.',
    'CashOutAccount.create': 'Créer',
    'CashOutAccountAdd.loyaltyId': 'Identifiant de fidélité ou numéro de carte',
    'CashOutAccountAdd.email': 'Adresse électronique',
    'CashOutAccountAdd.description': 'La description',
    'CashOutAccountAdd.enabled': 'Activer',
    'CashOutAccountUpdate.header': 'Mettre à jour les comptes',
    'CashOutAccountUpdate.subText': 'Vous pouvez activer / désactiver vos comptes ci-dessous.',
    'CashOutAccountUpdate.tableColumnHeaderType': 'Type',
    'CashOutAccountUpdate.tableColumnHeaderUserName': 'Email/ID',
    'CashOutAccountUpdate.tableColumnHeaderDescription': 'La description',
    'CashOutAccountUpdate.tableColumnHeaderAction': 'Action',
    'CashOutAccountUpdate.enable': 'ACTIVER',
    'CashOutAccountUpdate.disable': 'Désactiver',
    'CashOutAccountConfirm.message': 'Êtes-vous sûr de vouloir',
    'CashOutAccountConfirm.the': 'la',
    'CashOutAccountConfirm.account': 'Compte',
    'CashoutConfirmation.review': 'Examen et Transfert',
    'CashoutConfirmation.typeOfPayment.loyalty': 'Carte de fidélité',
    'CashoutConfirmation.typeOfPayment.paypal': 'PayPal',
    'CashoutConfirmation.typeOfPayment.charity': 'Donner a une oeuvre de charité',
    'CashoutConfirmation.transferTo': 'Transfert {amount} vers',
    'CashoutConfirmation.successfullyTransfer': 'Transfert réussi de {amount} vers',
    'CashoutConfirmation.transferFailed': 'le transfert a échoué: ',
    'CashoutWrapper.minTransfer': 'Transfert minimum: $20.00',
    'CashoutWrapper.currentBalance': 'Solde actuel: {amount}',
    // cashout wrapper select account
    'CashoutWrapper.loyaltyCardBalance': ' Loyalty Card Balance: {amount}',
    'CashoutWrapper.availableToCashOut': ' Available for Cash Out: {amount}',

    'CashoutConfirmation.amount': 'Montant',
    'CashoutConfirmation.edit': 'Modifier',

    'CashBackActive.empty':
      "Il n'y a actuellement aucune offre de remboursement. Veuillez vérifier à l'avenir pour plus d'offres.",
    'CashBack.totalsHeader': 'Disponible à retirer pour tous vos comptes',
    'CashBack.redemptionRedeem': 'Échangez ici',
    'CashBack.accountHeader': 'Sélectionnez le compte que vous souhaitez utiliser',
    'CashBack.accountNotFoundClick': 'Cliquez sur',
    'CashBack.accountNotFoundHere': 'ici',
    'CashBack.accountNotFoundToCreate': 'pour créer un compte pour ce type',
    'CashBack.amountHeader': 'Compte sélectionné',
    'CashBack.amountInputLabel': 'Montant à retirer',
    'CashBack.amountMaxValue': 'Maximum disponible pour le compte',
    'CashBack.confirmHeader':
      'Veuillez consulter les informations ci-dessous et confirmer que vous souhaitez transférer le montant du retrait sur votre compte sélectionné',
    'CashBack.confirmAccount': 'Compte sélectionné',
    'CashBack.confirmAmount': 'Montant de retrait',

    'Group.empty':
      "Il n'y a actuellement aucune offre de groupe. Veuillez vérifier à l'avenir pour plus d'offres.",
    'Group.clipAll': 'Veuillez couper toutes les offres ci-dessous',
    'Group.clipOne': "Veuillez choisir l'une des offres ci-dessous",
    'Group.clipMoreOffers': "Clip Plus d'offres",

    'OfferGroup.default': 'Défaut',
    'OfferGroup.relevancy': 'Pertinence',

    'Navigation.gallery': 'Bons',
    'Navigation.allclub': 'Offres du Club',
    'Navigation.reward': 'Récompenses',
    'Navigation.wallet': 'Portefeuille',
    'Navigation.profile': 'Profil',

    'GalleryWrapper.digital': 'Coupons numériques',
    'GalleryWrapper.coupon': 'Coupon',
    'GalleryWrapper.coupons': 'Coupons',
    'GalleryWrapper.flex': 'Comment voulez-vous voir les Flex Fffers ?',
    'GalleryWrapper.flexoffer': 'Offres flexible',
    'GalleryWrapper.flexcashlater': 'Encaisser plus tard',
    'GalleryWrapper.flexcashback': 'Remise en argent',
    'GalleryWrapper.flexsaving': 'économies instantanées',

    'WalletWrapper.title': 'Portefeuille',

    'CashoutWrapper.loading': 'Chargement des soldes...',
    'CashoutWrapper.loyalty': 'Carte de fidélité',
    'CashoutWrapper.paypal': 'PayPal',
    'CashoutWrapper.paypalEmailAddress': 'Paypal Email Address',
    'CashoutWrapper.charity': 'Donner a une oeuvre de charité',
    'CashoutWrapper.cashout': 'Encaissement',
    'CashoutWrapper.cardbalance': 'Solde actuel de la carte de fidélité',
    'CashoutWrapper.cashoutavail': 'Disponible pour le retrait',
    'CashoutWrapper.selectaccount': 'Sélectionnez un compte',
    'CashoutWrapper.recent': 'transactions récentes',
    'CashoutWrapper.noRecentEmail': 'No Recent Emails For Paypal Transactions.',

    'Filter.categories': 'Catégories',
    'Filter.clear': 'Claire',

    'Skip.content': 'Passer au contenu principal',

    'Badge.new': 'NEW',
    'Badge.endingsoon': 'ENDING SOON',
    'Badge.expiring': 'EXPIRING',
    'Badge.startingsoon': 'SCOMMENCER BIENTÔT',
    'Badge.featured': 'EN VEDETTE',

    'CouponHistory.title': 'Historique des coupons',
    'CouponHistory.offer': 'Offre',
    'CouponHistory.status': 'Statut',
    'CouponHistory.brand': 'Marque',
    'CouponHistory.date': 'Date',
    'CouponHistory.print': 'Imprimer',

    'register.alreadyAccount': 'Vous avez déjà un compte?',
    'register.createHeader': 'Créer un compte',
    'register.enterInfo':
      "Merci de votre inscription! inscrivez-vous ci-dessous pour recevoir des coupons numériques personnalisés. Courriel valide requis. Soyez à l'affût des offres qui s'offrent à vous !",
    'MyAccount.title': 'Mon compte',
    'MyAccount.profile': 'Profil',
    'MyAccount.helpful': 'Liens utiles',
    'MyAccount.profileRedirectBtn': 'Afficher/modifier le profil',
    'MyAccount.manageProfileTitle': 'Gérer les détails de votre profil',
    'MyAccount.manageProfileDesc1':
      'En cliquant sur ce lien, vous serez redirigé vers notre centre de service de données centralisé.',
    'MyAccount.manageProfileDesc2':
      "N'oubliez pas de revenir à cet onglet une fois que vous avez terminé d'apporter des modifications.",
    'MyAccount.subscribe_Unsubscibe_text': 'from promotional and marketing emails.',
    'CreateAccount.marketing_text':
      'By clicking “Create An Account” you are agreeing to receive general promotional and marketing materials, via email. You can opt out of these preferences at any time from My Account.',
    'Profile.updateButton': 'Sauvegarder les modifications',
    'Profile.showmore': 'Montre plus',
    'Profile.showless': 'Montrer moins',

    'GuidedTour.welcome': 'Bienvenue',
    'GuidedTour.offerviewing': 'Options de visualisation des offres',
    'GuidedTour.mywallet': 'Mon portefeuille',
    'GuidedTour.tryitout': 'Essaye le',
    'GuidedTour.back': 'Dos',
    'GuidedTour.welcomecontent':
      "Vous avez désormais le choix pour profiter de vos offres ! Les offres Flex vous permettent d'ajouter une remise à votre compte client qui peut être utilisée lors de votre prochain achat, ou une valeur qui peut être accumulée et « encaissée » sous la forme d'une remise à appliquer sur un achat futur.",
    'GuidedTour.couponcontent':
      "Un coupon est une réduction qui s’applique instantanément au moment de l’achat. Une fois l'offre attachée à votre compte, vous pouvez l'utiliser en effectuant l'achat requis et en vous identifiant lors du paiement (par exemple, entrez votre numéro de téléphone, scannez la carte de fidélité ou le code-barres).",
    'GuidedTour.cashbackcontent':
      "Une offre de remise en argent vous permettra de gagner et d’accumuler une remise à utiliser sur un prochain achat. Si cette option est sélectionnée, effectuez simplement le(s) achat(s) requis et voyez vos économies futures s'additionner dans le total « Cash Out »",
    'GuidedTour.flexoffercontent':
      'Une offre flexible vous laisse le choix de la manière d’utiliser une offre. Vous pouvez choisir une remise instantanée en sélectionnant « Coupon » ou en sélectionnant « Cash Back » et gagner la remise à utiliser à une date ultérieure.',
    'GuidedTour.offerviewingcontent':
      "Consultez les offres qui correspondent à vos préférences en matière de méthode d'échange.",
    'GuidedTour.cashoutcontent':
      'Le montant total de la remise en argent accumulé est visible ici. Cliquez ici et suivez les instructions sur les écrans suivants pour sélectionner le montant que vous souhaitez retirer.',
    'GuidedTour.mywalletcontent':
      'Vous pouvez voir toutes les offres coupées dans votre portefeuille. Les clips sont séparés par méthode de rachat ; des coupons ou des remises en argent.',

    'UserName.updateSuccess': "Votre nom d'utilisateur a été mis à jour avec succès !",
    'Error.userNameError':
      "Votre nom d'utilisateur n'a pas pu être mis à jour, veuillez vérifier les erreurs et réessayer.",
    'username.enterInfo':
      'Le bouton Enregistrer enregistrera cette valeur et redirigera vers le profil après une mise à jour réussie.',

    'Print.title': 'Liste de courses',
    'Print.subHeadertest': 'Voici votre',
    'Print.search': 'Recherche',
    'Print.sort': 'Trier par',
    'Print.offers': "Détails de l'article",
    'Print.totalcoupon': 'Total des coupons',
    'Print.category': 'Catégorie',
    'Print.expiry': 'Expiration',
    'Print.expires': 'EXPIRES',
    'Print.emptyText':
      "Vous n'avez pas de coupons découpés. Veuillez couper quelques coupons, puis réessayer.",

    'Pagetitle.Account': 'Compte',
    'Pagetitle.AccountPassword': 'Compte - Changer le mot de passe',
    'Pagetitle.AccountProfile': 'Profil du compte',
    'Pagetitle.Signup': "S'inscrire",
    'Pagetitle.Resetpassword': 'réinitialiser le mot de passe',
    'Pagetitle.Preferedstore': 'Compte - Magasin préféré',
    'Pagetitle.Cashout': 'Retrait',
    'Pagetitle.Cluboffers': 'Offres Club',
    'Pagetitle.Couponhistory': 'Historique des coupons',
    'Pagetitle.Print': 'Imprimer les coupons',
    'Pagetitle.Gallery': 'Galerie de coupons',
    'Pagetitle.Forgot': 'Mot de passe oublié',
    'Pagetitle.Signin': 'Se connecter',
    'Pagetitle.Splash': 'Éclaboussure',
    'Pagetitle.UsernameUpdate': "Compte - Mettre à jour le nom d'utilisateur",
    'Pagetitle.Wallet': 'Portefeuille électronique',

    'Rewards.Rewards': 'Rewards',
    'Rewards.expires': 'Expires:',
    'Rewards.locked': 'Locked',
    'Rewards.Amount_Spent': 'Amount Spent',
    'Rewards.Points_Earned': 'Points Earned',
    'Rewards.Item_Purchased': 'Items Purchased',
    'Rewards.available_to_claim': 'Available to claim',
    'Rewards.Rewards_Claimed': 'Rewards Claimed',
    'Rewards.view_rewards': 'Réduire toutes les récompenses',
    'Rewards.expandRewards': 'Développez toutes les récompenses',
    'Rewards.claim': 'Claim',
    'Rewards.Item': 'Items',
    'Rewards.Points': 'Points',
    'Rewards.Spent': 'Spent',
    'Rewards.loyaltyProgramEmptyMsg': 'Welcome to Loyalty Programs',
    'Rewards.loyaltyProgramEmptyMsgText': 'Welcome to Loyalty Programs. ',
    'Rewards.waysToEarnTitle': 'Ways to Earn',

    'Language.English': 'Inglés (US)',
    'Language.Spanish': 'Espagnol (MX)',
    'Language.French': 'Francés (FR)',
    'CommunityReward.noEnrollMsg':
      "Vous n'êtes inscrit à aucun programme communautaire. Veuillez vous inscrire pour commencer votre participation",
  },
};

export default Object.freeze({ ...messages });
